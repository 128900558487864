import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CollectionModel, BillModel, CollectonPersonModel, User } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-collection-receive',
  templateUrl: './collection-receive.component.html',
  styleUrls: ['./collection-receive.component.css']
})
export class CollectionReceiveComponent implements OnInit {

  loading = false;
  error = '';
  searchBillModel: BillModel = new BillModel();
  persons1: CollectonPersonModel[] = [];
  colls: CollectionModel[] = [];
  collsSum: CollectionModel[] = [];
  billscolls: CollectionModel[] = [];
  selectedconfig: boolean = false;
  currentUser: User;

  constructor(private api: RayarApiService,
    private api1: AuthenticationService) {
    this.onSearchPerson();
    this.getCollPersons();
    this.searchBillModel.personId = 0;
    this.searchBillModel.billStatus = "";
    this.currentUser = this.api1.currentUserValue;
  }

  ngOnInit(): void {
  }

  selectChangeHandlercheck(event: any) {
    this.selectedconfig = event.target.checked;
  }


  getData() {
    var d6 = this.selectedconfig;
    return this.colls.filter(function (hero) {
      return ((d6 == false) || (d6 == true && hero.autoReviewCode > 0))
    });
  }

  downloadBills(u: CollectionModel) {
    this.api.downloadBlobFilePost("Download1/" + u.billId + "/Bill", null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            var fileName = u.billId + ".jpg";
            if (data.body.type == "application/zip") {
              fileName = u.billId + ".zip"
            } else if (data.body.type == "application/pdf") {
              fileName = u.billId + ".pdf"
            }
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = fileName;
            a.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }

  showDetail(u: CollectionModel) {
    this.colls.forEach(x => {
      if (u.collectionId == x.collectionId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          this.api.getCollsSearch(u.billId.toString(), 'GetColls').subscribe(result => { this.billscolls = result; },
            error => console.error(error));
        }
      }
      else
        x.showDetail = false;
    });
  }

  onSearchPerson() {
    this.searchBillModel.page = 0;
    if (this.searchBillModel.personId)
      this.searchBillModel.personId = parseInt(this.searchBillModel.personId.toString());
    this.api.oncollsView('collsForAdminReview', this.searchBillModel)
      .subscribe(result => { this.colls = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    this.onSearchPersonSum();
  }

  onSearchPersonSum() {
    this.api.oncollsView('collsForAdminReviewSumm', this.searchBillModel)
      .subscribe(result => { this.collsSum = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }


  getCollPersons() {
    this.api.getCollPersons().subscribe(result => {
      this.persons1 = result;
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }


  onReset() {
    this.searchBillModel = new BillModel();
    this.searchBillModel.personId = 0;
    this.onSearchPerson();
  }


  selectChangeHandler(event: any) {
    this.searchBillModel.fromDate = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.searchBillModel.toDate = event.target.value;
  }

  selectChangeHandler3(event: any) {
    this.searchBillModel.billStatus = event.target.value;
  }

  markReceived(u: CollectionModel) {
    if (!this.currentUser) return;
    if (u.mode == 'Cash' && !(this.currentUser.id === 928 || this.currentUser.id === 901 || this.currentUser.id === 909)) return;

    this.api.markReceived(u.collectionId)
      .subscribe(result => {
        this.onSearchPerson();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  autoReview() {
    if (confirm("Are you sure to Auto Review Cash Collection?")) {
      var collectionIds = [];
      this.colls.filter(function (hero) {
        return hero.autoReviewCode > 0
      }).forEach(x => { collectionIds.push(x.collectionId) });;
      this.api.markAutoReceived(collectionIds)
        .subscribe(result => {
          this.onSearchPerson();
        }, error => {
          this.error = error;
          this.loading = false; console.error(error)
        });
    }
  }
}
